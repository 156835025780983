import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import classes from "./CardColumn.module.css"
import CardDetailed from '../Card/CardDetailed';
function CardColumn() {
  return (
    <>
    <CardDetailed imageSrc="https://images.odishatv.in/uploadimage/library/16_9/16_9_0/IMAGE_1676737338.webp" text="PM SHRI School is a centrally sponsored scheme by the Government of India. This initiative is intended to develop more than 14500 PM SHRI Schools managed by Central Government/State/UT Government/local bodies including KVS and NVS in which every student feels welcomed and cared for, where a safe and stimulating learning environment exists, where a wide range of learning experiences are offered, and where good physical infrastructure and appropriate resources conducive to learning are available to all students.
      It will nurture students in a way that they become engaged, productive, and contributing citizens for building an equitable, inclusive, and plural society as envisaged by the National Education Policy 2020.
      PM SHRI School is a centrally sponsored scheme by the Government of India. This initiative is intended to develop more than 14500 PM SHRI Schools managed by Central Government/State/UT Government/local bodies including KVS and NVS in which every student feels welcomed and cared for, where a safe and stimulating learning environment exists, where a wide range of learning experiences are offered, and where good physical infrastructure and appropriate resources conducive to learning are available to all students.
      It will nurture students in a way that they become engaged, productive, and contributing citizens for building an equitable, inclusive, and plural society as envisaged by the National Education Policy 2020. As we continue to build an education system for the future,
      it is critical that the school ecosystem is resilient in the face of an increasingly unpredictable, dynamic and non-linear world. The PM SHRI schools are being designed to achieve that. These schools will help showcase the implementation of the National Education Policy 2020 and emerge as exemplar schools. 
      They will provide leadership in their respective regions in providing highquality education in an equitable, inclusive and joyful school environment that takes care of the diverse background, multilingual needs, and different academic abilities of children and makes them active participants in their learning process. Schools are incentivized to yield results and perform better through a funding mechanism that is tracked through quality parameters. A decentralised administration strategy will be followed to ensure the effective implementation of the scheme. A system of transparency and accountability will be created for the effective delivery of performance at school levels."
      anchorLink
      title="ABOUT PM SHRI SCHOOL"
      imageAlt="Banner"
/>
    <div className={classes.CardCol}>
        <div>
    <Card className={classes.CardColFirst}>
      <Card.Header className={classes.cardHeader}><h3>A. General Information</h3></Card.Header>
      <ListGroup variant="flush" className={classes.cardList}>
        <ListGroup.Item><h4>Name of School:</h4><p>PM SHRI Govt. Sr. Sec. School</p></ListGroup.Item>
        <ListGroup.Item><h4>School Code:</h4><p>01651</p></ListGroup.Item>
        <ListGroup.Item><h4>Address:</h4><p>PM SHRI Govt. Sr. Sec. School,Bishan Pura, Jind</p></ListGroup.Item>
        <ListGroup.Item><h4>Principal Name</h4><p>Mrs.Sunita Saini</p></ListGroup.Item>
        <ListGroup.Item><h4>Email:</h4><p>gsssbishanpura01651@gmail.com</p></ListGroup.Item>
        <ListGroup.Item><h4>Phone:</h4><p>+917206373112</p></ListGroup.Item>
      </ListGroup>
    </Card>
    </div>
    <div>
    <Card className={classes.CardColSecond}>
      <Card.Header className={classes.cardHeader}><h3>B. Document and Information</h3></Card.Header>
      <ListGroup variant="flush" className={classes.cardList}>
        <ListGroup.Item><p>Copy of valid Fire Safety Certificate issued by competent authority</p><a href='/FireCertificate.jpg'>View</a></ListGroup.Item>
        <ListGroup.Item><p>Copy of valid Sanitization and Hygiene Certificate issued by competent authority</p><a href='/HealthCertificate.jpg'>View</a></ListGroup.Item>
      </ListGroup>
    </Card>
    </div>
    <div>
    <Card className={classes.CardColThird}>
      <Card.Header className={classes.cardHeader}><h3>C. Staff(Teaching)</h3></Card.Header>
      <ListGroup variant="flush" className={classes.cardList}>
      <ListGroup.Item><h4>Principal</h4><p>Mrs.Sunita Saini</p></ListGroup.Item>
        <ListGroup.Item><h4>Total Teacher :17</h4><p>Total PGT :14<br/>Total TGT :03<br/>Total Students(Class 6TH to 12th) :483</p></ListGroup.Item>
        <ListGroup.Item><h4>Teacher Students Ratio</h4><p>1:40</p></ListGroup.Item>
      </ListGroup>
    </Card>
    </div>

    </div>
    </>
  );
}

export default CardColumn;


