import classes from "./CardDetailed.module.css";
import React from "react";
import { Card, Row, Col } from "react-bootstrap";

function CardDetailed(props) {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Row>
          <Col xs={12} md={4}>
            <img
              src={props.imageSrc}
              alt={props.imageAlt}
              className={classes["img-fluid"]}
            />
          </Col>
          <Col xs={12} md={8}>
            <Card.Title>
              <div className={classes.cardTitle}>{props.title}</div>
            </Card.Title>
            <Card.Text className={classes["card-text"]}>{props.text}</Card.Text>
          {props.anchorLink  &&  <a href="https://dsel.education.gov.in/pm-shri-schools" className={classes.anchor}>Click here for more information</a>}
          </Col>
        </Row>
      
      </Card.Body>
    </Card>
  );
}

export default CardDetailed;
