// components/Courses.js
import React from 'react';

function Courses() {
  return (
    <section id="courses">
      <h2>Our Courses</h2>
      <p>Course information goes here...</p>
    </section>
  );
}

export default Courses;
