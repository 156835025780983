import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import classes from  "./CardGroups.module.css";
function CardGroups() {

    const cardTexts =[
    {img:"/YOGA.jpg",title:"Yoga & Assembly",text:"Morning assemblies are the cornerstone of a school's daily routine, serving as a platform for fostering a sense of community and discipline among students. They provide an opportunity for students to start the day on a positive note with prayers, pledges, and motivational speeches, setting the tone for a productive day ahead."},
    {img:"/CulturalPics.jpg",title:"Cultural Programme",text:"Cultural events celebrate the rich diversity of traditions, customs, and art forms, providing students with a platform to showcase their cultural heritage and talents. These events promote cultural awareness, tolerance, and appreciation among students, fostering a sense of unity and inclusivity within the school community."},
    {img:"/Art.jpg",title:"Arts & Crafts",text:"Art and craft activities play a vital role in nurturing creativity, imagination, and self-expression among students. They provide a therapeutic outlet for students to explore their artistic abilities, experiment with different mediums, and unleash their potential, fostering a love for the arts and promoting holistic development."},
    {img:"/AwardCeremony.jpg",title:"Annual Function",text:"Annual days are much-awaited events that commemorate the achievements and milestones of the academic year. They provide an opportunity for students to showcase their talents in various fields such as music, dance, drama, and academics, instilling a sense of pride and accomplishment."},
    {img:"/Exibition.jpg",title:"Scientific Approach",text:"Science exhibitions play a crucial role in nurturing students' interest and aptitude in science and technology. These events encourage students to explore scientific concepts through hands-on experiments, projects, and demonstrations, fostering creativity and critical thinking skills."},
    {img:"/Outing.jpg",title:"Exposure Field Visit",text:"Exposure outings in schools hold immense importance in enriching students' educational experiences. These outings typically involve visits to various organizations, industries, or natural settings, allowing students to engage directly with real-world contexts related to their curriculum."}]

  return (
    <Row xs={1} md={3} className={`g-1 ${classes.customRow}`}>
      {Array.from({ length: 6 }).map((_, idx) => (
        <Col key={idx}>
          <Card className={classes.cards}>
            <Card.Img variant="top" src={cardTexts[idx].img} className={classes.image}/>
            <Card.Body>
              <Card.Title><h2 className={classes.heading}>{cardTexts[idx].title}</h2></Card.Title>
              <Card.Text>
             <p>{cardTexts[idx].text}</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default CardGroups;