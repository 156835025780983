import React from 'react';
import classes from './Contact.module.css'; // Import CSS file for styling

const Contact = () => {
  return (
    <div className={classes["contact-page"]}>
      <h2 className={classes.title} >Contact Us</h2>
      <div className={classes["contact-details"]}>
        <div className={classes["contact-info"]}>
          <h3>School Address</h3>
          <p>PM SHRI Government Senior Secondary School</p>
          <p>Bishanpura,Jind</p>
          <p>Haryana 126101</p>
        </div>
        <div className={classes["contact-info"]}>
          <h3>Contact Information</h3>
          <p>Phone: 9466250033</p>
          <p>Email: gsssbishanpura01651@gmail.com</p>
        </div>
      </div>
      <div className={classes["map-responsive"]}>
        <iframe
          title="School Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4888.0385955744605!2d76.32952279115285!3d29.270402327793434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39120588e500000f%3A0xabd52791f498b366!2sGovernment%20Senior%20Secondary%20School!5e0!3m2!1sen!2sin!4v1709645523727!5m2!1sen!2sin" 
          width="600"
          height="450" 
          style={{ border: 0 }}
          allowfullscreen="" 
          loading="lazy" 
          referrerpolicy="no-referrer-when-downgrade">

          </iframe>
      </div>
    </div>
  );
};

export default Contact;
