import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { RouterProvider, createHashRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Contact from './Components/Contact/Contact';
import Courses from './Components/Courses/Courses';
import Events from './Components/Events/Events';
import About from "./Components/About/About";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./Components/Home/Home";
import NSQF from './Components/NSQF/NSQF';
import Infrastructure from  "./Components/Infrastrucure/Infrastrucure";
import Curriculum from './Components/Curriculum/Curriculum';
import Achievements from "./Components/Achievements/Achievements";


const router = createHashRouter([
  {path:"/",
  element: <App/>,
  children:[
    {path:"/",element:<Home/>},
    {path:"/contact",element:<Contact/>},
    {path:"/courses",element:<Courses/>},
    {path:"/events",element:<Events/>},
    {path:"/about",element:<About/>},
    {path:"/nsqf", element:<NSQF/>},
    {path:"/infrastructure",element:<Infrastructure/>},
    {path:"/curriculum",element:<Curriculum/>},
    {path:"/achievements",element:<Achievements/>}
  ]
  },
])
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
