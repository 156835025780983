import Carousel from 'react-bootstrap/Carousel';
import classes from "./Home.module.css";
import Card from '../Card/Card';
import CardDetailed from "../Card/CardDetailed";
import SchoolIcon from '@mui/icons-material/School';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CardGroups from '../Card/CardGroups';

function Home() {
  let message = (
    <pre  id="dynamicPre">
      {`
        Dear Students, Parents, Teachers, and Visitors,

        It gives me immense pleasure to welcome you to the official website of PM SHRI Govt. Sr. Sec. School Bishan Pura, Jind.
        As the Principal of this esteemed institution, it is my privilege to extend my warmest greetings to all members of our
        school community and those who are considering joining us.

        Our website serves as a gateway to the vibrant  world of PM SHRI School Bishan Pura , offering valuable information about
        our academic programs, extracurricular activities, and the various resources available to support student learning and
        development.

        We are committed to fostering a nurturing  environment where every student is empowered to excel academically,
        socially, and emotionally.Through our rigorous curriculum, dedicated faculty, we strive to equip our students 
        with the knowledge, skills,and values necessary to succeed in an ever-changing world.

        I encourage you to take a closer look at the diverse opportunities that await you here. Whether you are interested
        in exploring our academic offerings,participating in our extracurricular activities, or learning more about our 
        community outreach initiatives, you will find a wealth of information to inspire your curiosity.

        Thank you for visiting our website, and I hope you find it informative and engaging. If you have any questions 
        please do not hesitate to reach out to us.

        We are here to support you every step of the way on your educational journey.

        Warm regards,
        Sunita Saini
      `}
    </pre>
  );

  return (
    <>
      <Carousel>
        <Carousel.Item interval={1000}>
          <img className={classes.bannerImage} src='/PrizeDistribution.jpg' alt=''/>
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <img className={classes.bannerImage} src='/IMG-20240228-WA0013.jpg' alt=''/>
        </Carousel.Item>
        <Carousel.Item>
          <img className={classes.bannerImage} src='IMG-20240228-WA0003.jpg' alt=''/>
        </Carousel.Item>
      </Carousel>
      
      <div className={classes.pmCards}>
        <Card icon={<SchoolIcon/>} title="Active Learning" text="Active Learning methodologies engage students in hands-on experiences, collaborative projects, and critical thinking exercises, fostering a deeper understanding of concepts and promoting lifelong learning skills. By actively participating in their education, students become empowered learners who take ownership of their academic journey."/>
        <Card icon={<Diversity3Icon/>} title="Qualified Teachers" text="Highly Qualified Teachers bring expertise, passion, and dedication to the classroom. Their proficiency in their respective fields, coupled with their ability to connect with students on a personal level, creates an environment conducive to academic excellence and personal growth. These educators serve as mentors, inspiring students to reach their full potential."/>
        <Card icon={<CameraAltIcon/>} title="Secure Facility" text="A Secure Facility provides a safe and nurturing environment where students can thrive academically, emotionally, and socially. By prioritizing the safety and well-being of students & staff schools create a conducive atmosphere for learning. A secure facility encompasses robust safety protocols, ensuring peace of mind for all stakeholders."/>
      </div>

      <CardDetailed imageSrc="/IMG-20220903-WA0034.jpg" title="Message from the Principal" text={message} specificClass="principalMessage" imageAlt="Banner"/>
      
      <CardGroups/>
    </>
  );
}

export default Home;
