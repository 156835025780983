import React from 'react';
import classes  from './NSQF.module.css'; // Import CSS file for styling

const NSQFInfo = () => {
  return (
    <div className={classes["nsqf-info"]}>
      <h2>National Skills Qualification Framework (NSQF): Empowering India's Workforce</h2>
      <p>
        In a rapidly evolving global economy, the demand for a skilled workforce is more crucial than ever. Recognizing this need, the Government of India introduced the National Skills Qualification Framework (NSQF), a transformative initiative aimed at standardizing and enhancing vocational education and training across the nation. NSQF serves as a blueprint for integrating skills development into mainstream education, fostering employability, and promoting lifelong learning.
      </p>
      <p>
        At its core, NSQF is designed to bring coherence and consistency to the diverse skill development landscape in India. It organizes qualifications according to a series of levels, ranging from entry-level skills to advanced competencies, ensuring that learners can progress seamlessly along their chosen career paths. This hierarchical structure not only provides clarity but also facilitates recognition and mobility within the job market.
      </p>
      <p>
        One of the key principles underlying NSQF is the concept of 'learning outcomes.' Rather than focusing solely on inputs such as the duration of training or the curriculum covered, NSQF emphasizes the skills, knowledge, and attitudes that learners are expected to acquire at each level. This shift towards outcome-based education promotes a more practical and application-oriented approach, aligning training programs with the needs of industries and employers.
      </p>
      <p>
        Furthermore, NSQF promotes the integration of formal, informal, and non-formal learning pathways, recognizing that skills can be acquired through various means. Whether through traditional academic institutions, vocational training centers, apprenticeships, or even self-directed learning, individuals are encouraged to pursue avenues that best suit their aspirations and circumstances. This inclusivity expands access to education and empowers individuals from diverse backgrounds to participate in the workforce.
      </p>
      <div className={classes.linkAnchor}><a href='https://www.nielit.gov.in/content/nsqf'>Click here for more information</a></div>
    </div>
  );
}

export default NSQFInfo;
