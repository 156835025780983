// components/About.js
import React from 'react';
import CardColumn from '../Card/CardColumn';

function About() {
  return (
   <>
   <CardColumn/>
   </>
  );
}

export default  About;
