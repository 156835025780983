import React from "react";
import "./App.css";
import { Outlet } from "react-router-dom";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import TransparentBox from "./Components/TransparentBox/TransparentBox";
function App() {
  return (
    <>
      <Header />
      <Outlet />
      <TransparentBox/>
      <Footer />
    </>
  );
}

export default App;
