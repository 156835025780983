import classes from  "./Curriculum.module.css";
function Curriculum(){
return(
    <>
    <h2 className={classes.title}>Curriculum</h2>
    <div><p className={classes.paragraph}>
    PM SHRI Govt. Sr. Sec. School Bishan Pura, Jind is affiliated to Board of School Education Haryana. The school prepare the students for secondary
     school and senior school examination in all the three streams viz SCIENCE, ARTS & COMMERCE.
    A well-structured curriculum not only covers academic disciplines such as mathematics, science, language arts,
 and social studies but also integrates practical skills, critical thinking, and creativity. It adapts to the 
 evolving needs of learners and society, incorporating diverse perspectives, technology advancements, and 
 global challenges. Moreover, a comprehensive curriculum promotes lifelong learning, empowers students to 
 explore their interests, and cultivates a deep understanding of the world around them. Through an effective
  curriculum, educators inspire curiosity and nurture individuals who are equipped to
   thrive in an ever-changing world.</p></div>

<div className={classes.container}>
    <h5 className={classes.subheading}>VI-VIII</h5>
    <p className={classes.para}>English, Hindi, Math, Science, Social Science , Drawing, Sanskrit</p>
    <h5 className={classes.subheading}>IX-X</h5>
    <p className={classes.para}>English, Hindi, Math, Science, Social Science, Physical Education, Sanskrit, Auto Mobile</p>

    <h5 className={classes.subheading}>XI-XII</h5>
    <p className={classes.para}>English(core), Hindi(core), Math, Economics, History, Geography, Sanskrit, Physical Education, Chemistry, Physics, Biology, Political Science</p>

<h4 className={classes.secondHeading}>Fees Structure</h4>
<div><h6  className={classes.subheading}>VI-VIII</h6><p className={classes.para}>NIL</p></div>
<div className={classes.display}>
<div><h6 className={classes.subheading}>IX-X</h6>
<p className={classes.para}>Admission fees-- ₹ 5 one time</p>
<p className={classes.para}>CCWF---₹ 25monthly</p>
<p className={classes.para}>JRC---₹ 45yearly</p>
<p className={classes.para}>CWF---₹ 24yearly</p>
</div>
<div><h6 className={classes.subheading}>XI-XII(ARTS)</h6><p className={classes.para}>
Admission fees---₹ 20 one time</p>
<p className={classes.para}>CCWF---₹ 50monthly</p>
<p className={classes.para}>JRC---₹ 45yearly</p>
<p className={classes.para}>CWF---₹ 24yearly</p>
</div>
</div>
<div className={classes.display}>
<div><h6 className={classes.subheading}>XI-XII(SCIENCE)</h6><p className={classes.para}>
Admission fees---₹ 20 one time</p>
<p className={classes.para}>CCWF---₹ 75monthly</p>
<p className={classes.para}>JRC---₹ 45yearly</p>
<p className={classes.para}> CWF---₹ 24yearly</p>
</div>
<div><h6 className={classes.subheading}>XI-XII(COMMERCE)</h6><p className={classes.para}>
Admission fees---₹ 20 one time</p>
<p className={classes.para}>CCWF---₹ 60monthly</p>
<p className={classes.para}>JRC---₹ 45yearly</p>
<p className={classes.para}> CWF---₹ 24yearly</p>
</div>
</div>
</div>
    </>
)
}
export default Curriculum;