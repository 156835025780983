import React from 'react';
import classes from './Footer.module.css'; 
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
const Footer = () => {
  return (
    <footer className={classes["footer"]}>
      <div className={classes["footer-content"]}>
        <div className={classes["footer-column"]}>
          <h3>About Us</h3>
          <p>The Cabinet has been approved a newly central sponsored scheme called PM SHRI on 7 September, 2022. These schools will showcase the implementation of the National Education Policy 2020 and
             emerge as exemplar schools over a period of time, and also offer leadership to other schools in the neighborhood.</p>
        </div>
        <div className={classes["footer-column"]}>
          <h3>Contact Us</h3>
          <p>Email: gsssbishanpura01651@gmail.com</p>
        </div>
        <div className={classes["footer-column"]}>
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="#"><InstagramIcon/></a>
            <a href="#"><FacebookIcon/></a>
            <a href="#"><TwitterIcon/></a>
          </div>
        </div>
      </div>
      <div className={classes["footer-bottom"]}>
        <p>&copy; 2024 PM SHRI Government Senior Secondary School, Bishanpura, Jind. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
