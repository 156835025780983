import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import classes  from "./Infrastrucure.module.css";
const Infrastructure = () => {

  const data = [{sn:1,place:"Class Room",count:14},{sn:2,place:"Physics Lab",count:1},{sn:3,place:"Chemistry Lab",count:1},
  {sn:4,place:"Biology Lab",count:1},{sn:5,place:"Computer Lab",count:1},{sn:6,place:"Science Lab",count:1},{sn:7,place:"Library Room ",count:1},
  {sn:8,place:"Auto mobile Room",count:1},{sn:9,place:"Maths activity Room ",count:1},{sn:10,place:"Physical Education activity",count:1},
  {sn:11,place:" Mid day meal kitchen",count:1},{sn:12,place:"Principal Cum CRC Room ",count:1},];

  return (
    <>
    <h2 className={classes.title}>School Infrastructure & Teaching Staff Details</h2>
    <Container  className={classes.container}>
        <Row className={classes.heading}>
        <Col><h5>S.No.</h5></Col>
        <Col><h5> Details</h5></Col>
        <Col><h5>No.</h5></Col>
        </Row>
       {data.map((item, index) => (
      <Row key={index}>
        <Col>{item.sn}</Col>
        <Col><h6>{item.place}</h6></Col>
        <Col>{item.count}</Col>
      </Row>))}
      </Container>
      <Row>
        <h4  className={classes.secondHeading}>Teaching Staff Details</h4>
      <Col><img src='/StaffDetail1.jpg' alt='sd' className={classes.imgFluid}/></Col>
      <Col><img src='/SD2.jpg' alt='sd' className={classes.imgFluid}/></Col>
      <Col><img src='/SD3.jpg' alt='sd' className={classes.imgFluid}/></Col>
      </Row>
      <Row>
        <h4  className={classes.secondHeading}>Enrollment</h4>
      <Col><img src='/Enrollment.jpg'  alt='sd' className={classes.imgFluid}/></Col>
      </Row>
    </>

  );
}

export default Infrastructure;
