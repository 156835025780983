// components/Events.js
import React from 'react';
import classes from "./Events.module.css"
;function Events() {
  let photos=[{src:"/Art.jpg",title:"a"},{src:"/Assembly.jpg",title:"b"},{src:"/AwardDistribution.jpg",title:"d"},{src:"/IMG-20240228-WA0002.jpg",title:"e"},{src:"/IMG-20240228-WA0013.jpg",title:"j"},
  {src:"/CulturalPics.jpg",title:"f"},{src:"/Exibition.jpg",title:"g"},{src:"/OutingPic.jpg",title:"h"},{src:"/Play.jpg",title:"i"},
  {src:"/Science.jpg",title:"l"},{src:"/StudentArt.jpg",title:"m"},{src:"/StudentExhibition.jpg",title:"n"},{src:"/StudentProject.jpg",title:"o"}
]
  return (
        <div className={classes.container}>
          <h2  className={classes.title}>Events Conducted At School</h2>
          <div className={classes.row}>
            {photos.map((photo, index) => (
              <div key={index} className={classes["col-md-3 mb-4"]}>
                <img src={photo.src} alt={photo.title} className={classes.imgFluid} />
              </div>
            ))}
          </div>
        </div>
  );
}

export default Events;
