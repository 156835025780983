import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import classes from "./Header.module.css";

function Header() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container >
        <Navbar.Brand className={classes.pmTitleContainer} href="/">
          <img
            alt="emblem"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/Emblem_of_Haryana.svg/764px-Emblem_of_Haryana.svg.png"
            width="100px"
            height="100px"
            className="d-inline-block align-top"
          />
          <div className={classes.schoolName}>
            <h1> PM SHRI Govt. Sr. Sec. School </h1>
            <h3>Bishan Pura, Jind</h3>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle
          className={classes.pmNavbarToggle}
          aria-controls="basic-navbar-nav"
        />
        <Navbar.Collapse id="basic-navbar-nav" className={classes.container}>
          <Nav className="justify-content-end flex-grow-1">
            <Nav.Link>
              <NavLink className="nav-link" to="/">
                Home
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink className="nav-link" to="/about">
              About
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink className="nav-link" to="/events">
                Events
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink className="nav-link" to="/contact">
                Contact
              </NavLink>
            </Nav.Link>
          </Nav>
          <Nav className={classes.dropdown}>
            <NavDropdown title="Academics & Infrastructure" menuVariant="white">
              <NavDropdown.Item  as={Link}  to="/curriculum">Curriculum</NavDropdown.Item>
              <NavDropdown.Item  as={Link} to="/nsqf">
                NSQF
              </NavDropdown.Item>
              <NavDropdown.Item as={Link}  to="/infrastructure">School Infrastructure</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link}  to="/achievements">
                Academic Achievements
              </NavDropdown.Item>
              <NavDropdown.Item href="https://mis.highereduhry.ac.in/">
                MIS
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
