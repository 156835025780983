import classes from "./Achievements.module.css";
import React from 'react';
import { Container, Row } from 'react-bootstrap';
function Achievements(){
    return(
        <>
         <h2 className={classes.title}>School Achievments</h2>
         <div><p className={classes.para}>
         The 10th and 12th-grade results of our school stand as a testament to the dedication, hard work, and academic excellence exhibited by our students.
          Year after year, our students have consistently achieved remarkable results,
           showcasing their exceptional knowledge and skills in various subjects. With a rigorous curriculum and
            supportive learning environment, our school has empowered students to excel not only academically but
             also holistically. The outstanding performance of our students in board examinations reflects the commitment
              of our educators, the support of parents, and the determination of our students to reach new heights of
               success. Beyond academic achievements, our students have demonstrated leadership qualities, critical
                thinking abilities, and a strong sense of responsibility, preparing them for the challenges and
                 opportunities that lie ahead in their academic and professional journeys. As a school community,
                  we take pride in the accomplishments of our students and remain committed to nurturing their talents
                  and guiding them towards a bright and promising future.

</p></div>

<Container className={classes.container}>
<Row>
        <h3  className={classes.secondHeading}>Class Xth Results</h3>
      <img src='/R10.jpg'  alt='res' className={classes.imgFluid}/>
      </Row>
      <Row>
        <h3  className={classes.secondHeading}>Class XIIth Results</h3>
      <img src='/R12.jpg'  alt='res' className={classes.imgFluid}/>
</Row>
</Container>
        </>
    )
}
export default Achievements;