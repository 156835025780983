import Card from 'react-bootstrap/Card';
import classes from "./Card.module.css";

function CardComponent({title,text,icon}) {
  return (
    <Card className={classes.infoCard}>
      <Card.Body>
        <Card.Title>
            <div className={classes.icons} style={{ fontSize: '40%' }}>{icon}</div>
            <h2 className={classes.heading}>{title}</h2>
        </Card.Title>
        <Card.Text>
        <p>{text}</p>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default CardComponent;