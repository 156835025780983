import React from "react";
import classes from "./TransparentBox.module.css";
const TransparentBox = () => {
  return (
    
      <div className={classes.transparentBoxContainer}>
        <div className={classes.backgroundImage}>
          <div className={classes.content}>
            <h1>Enroll your child</h1>
            <h3>Admission for 2024-25 is open</h3>
            <p className={classes.paragraph}>
              At our school, we prioritize creating a nurturing and inclusive
              community that celebrates diversity and encourages each child to
              reach their full potential. Our dedicated team of educators is
              passionate about inspiring a love for learning and fostering
              critical thinking, creativity, and resilience in our students.
            </p>
          </div>
        </div>
      </div>
  );
};

export default TransparentBox;
